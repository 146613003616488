import { call, put, takeEvery } from 'redux-saga/effects';

// Api-Services
import { addJobApi, deleteJobApi, editJobApi, getJobByIdApi, getJobsListApi } from '../apis/jobs';

// Constants
import { ADD_JOB, DELETE_JOB, GET_JOB_BY_ID, GET_JOBS, UPDATE_JOB } from '../../constants/redux';
import { RESPONSE_STATUS_CODES } from '../../constants/common';

// Redux-Actions
import { deleteJob, editJobDetails, jobsDetails, updateData } from '../slices/jobs';
import { apiSuccess } from '../slices/apiStatus';
import { toast } from 'react-toastify';

function* JobsListSaga(action) {
  try {
    const response = yield call(getJobsListApi, { ...action.payload });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      const jobs = result.jobs;
      const pagination = result.pagination;
      yield put(jobsDetails({ jobs, pagination }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* addJobSaga(action) {
  try {
    const response = yield call(addJobApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.CREATED) {
      toast.success('Job Added Succesfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
      yield put(updateData());
    }
  } catch (error) {
    console.log(error);
  }
}

function* getJobByIdSaga(action) {
  const ID = action.payload;
  const response = yield call(getJobByIdApi, ID);
  if (response.status === RESPONSE_STATUS_CODES.OK) {
    const jobDetails = response.data.data;
    console.log('object', jobDetails);
    yield put(editJobDetails({ jobDetails }));
    yield put(apiSuccess());
  }
}

function* editJob(action) {
  try {
    const response = yield call(editJobApi, action.payload);
    if (response.status === RESPONSE_STATUS_CODES.SUCCESS) {
      toast.success('Detail have been Updated Successfully', {
        position: toast.POSITION.BOTTOM_RIGHT
      });
      yield put(apiSuccess());
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteJobData(action) {
  try {
    const response = yield call(deleteJobApi, action.payload);
    if (response?.status) {
      yield put(deleteJob(action.payload));
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* watcherJobsListSaga() {
  yield takeEvery(GET_JOBS, JobsListSaga);
  yield takeEvery(ADD_JOB, addJobSaga);
  yield takeEvery(UPDATE_JOB, editJob);
  yield takeEvery(GET_JOB_BY_ID, getJobByIdSaga);
  yield takeEvery(DELETE_JOB, deleteJobData);
}
