import { useEffect, useState } from 'react';

// Components
import DataTable from '../../components/DataTable';
import AddEditJob from './AddEditJob';
import { ActionButtonWithoutView } from '../../components/ActionButtons';

// Constants
import { JOBS_COLUMNS } from '../../constants/tableColumns';
import {
  ACTION_TYPE,
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  TABLE_NAME
} from '../../constants/common';
import { debounce } from '../../utils/debounce';
import { isDarkMode } from '../../utils/common';

// Redux
import { deleteJob, getJobById, getJobsList } from '../../redux/actions/jobs';

// Utility Packages
import { Chip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { confirmDialog } from 'primereact/confirmdialog';

const jobsTableStates = {
  searchValue: '',
  pageNumber: INITIAL_PAGE_NUMBER,
  size: INITIAL_SIZE,
  sortBy: 'DateModified',
  orderBy: 'desc'
};

const Jobs = () => {
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.jobs);

  const [tableStates, setTableStates] = useState(jobsTableStates);
  const [jobsData, setJobsData] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [addEditJobModal, setAddEditJobModal] = useState(false);

  useEffect(() => {
    dispatch(getJobsList(tableStates));
  }, [tableStates, jobs?.isDeleted, jobs?.isUpdated, jobs?.isAdded]);

  const handleRowActions = (actionType, rowId) => {
    if (actionType === ACTION_TYPE.EDIT) {
      setSelectedJob(rowId);
      dispatch(getJobById(rowId));
      setAddEditJobModal(true);
    } else if (actionType === ACTION_TYPE.DELETE) {
      confirmDialog({
        header: 'Delete',
        message: 'Are you sure you want to delete?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => dispatch(deleteJob(rowId))
      });
    }
  };

  useEffect(() => {
    const modifiedJobs = jobs?.jobs?.map((job, index) => {
      return {
        ...job,
        Salary: job.Salary ? `${job.Salary} LPA` : '--',
        Experience: job.Experience ? `${job.Experience} Y` : '--',
        Skills: (
          <ul className="technology-list" key={index}>
            {job?.Skills && job?.Skills.length > 0
              ? job?.Skills?.map((tech, innerIndex) => (
                  <Chip key={innerIndex} label={tech} className="single-param mr-2" />
                ))
              : '--'}
          </ul>
        ),
        action: (
          <div className="flex align-items-center">
            <ActionButtonWithoutView
              onClick={(actionType) => handleRowActions(actionType, job?.ID)}
            />
          </div>
        )
      };
    });
    setJobsData(modifiedJobs);
  }, [jobs, isDarkMode()]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });
  };

  const handleSort = (sortBy) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        sortBy,
        orderBy: prevState.orderBy === 'asc' ? 'desc' : 'asc'
      };
    });
  };

  const handleAdd = () => {
    setAddEditJobModal(true);
  };

  return (
    <>
      <DataTable
        name={TABLE_NAME.JOBS}
        searchPlaceHolder="Search by Job Title"
        totalRecords={jobs?.pagination?.totalRecords || 0}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        onSort={handleSort}
        onAdd={() => handleAdd()}
        data={jobsData}
        columns={JOBS_COLUMNS}
      />

      {addEditJobModal && (
        <AddEditJob
          show={addEditJobModal}
          ID={selectedJob}
          onHide={() => {
            setSelectedJob(null);
            setAddEditJobModal(false);
          }}
        />
      )}
    </>
  );
};

export default Jobs;
