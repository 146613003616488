import api from '../../utils/axios';
import { API_ROUTES } from '../../constants/apiRoutes';

export const getJobApplicationsListApi = async ({
  pageNumber,
  searchValue,
  sortBy,
  orderBy,
  fromHr,
  size
}) => {
  try {
    const response = await api.get(
      `${API_ROUTES.CANDIDATE_JOB_APPLICATIONS}?page=${pageNumber}&searchName=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}&fromHr=${fromHr}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
