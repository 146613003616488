import React, { useRef, useState } from 'react';

// Prime-React Components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

// Utils
// Hide Export Functionality
// import { saveAsExcelFile } from '../utils/excel';

// Constants
import { PAGINATION_OPTIONS } from '../constants/dropdownOptions';
import { getUserTableName } from '../utils/common';
import { CustomeTooltip } from './ActionButtons';
import { MultiSelect } from 'primereact/multiselect';
import { Menu, TextField } from '@mui/material';

const CustomDataTable = (props) => {
  const tableRef = useRef(null);
  const tableData = props.data;
  // Hide Export Functionality
  // const tableExportedData = props.exportData;
  const tableColumns = props.columns;
  const tableTotalRecords = props.totalRecords;
  const tableName = props.name;
  const [tableLazyParams, setTableLazyParams] = useState({
    first: 0,
    page: 1
  });
  const [selectedLimit, setSelectedLimit] = useState(null);
  const [minExp, setMinExp] = useState(props.minExp);
  const [maxExp, setMaxExp] = useState(props.maxExp);
  const [search, setSearch] = useState(props.searchValue);

  // Hide Export Functionality
  // const exportExcel = () => {
  //   const exportFields = tableColumns
  //     .filter((item) => item.isExport !== false)
  //     .map((column) => column.field);
  //   const exportData = tableExportedData.map((dataObj) => {
  //     const filteredObj = {};
  //     exportFields.forEach((fieldName) => (filteredObj[fieldName] = dataObj[fieldName]));
  //     return filteredObj;
  //   });
  //   saveAsExcelFile(tableName, exportData);
  // };

  const tableHeader = (
    <div className="table-header flex justify-content-between">
      <div className="table-actions-left flex-wrap">
        {props.onBack && (
          <div className="table-actions-right flex">
            <Button
              data-tip={getUserTableName(tableName)}
              type="button"
              className="add-user-btn"
              onClick={props.onBack}>
              <i className="pi pi-fw pi-arrow-left" />
            </Button>
          </div>
        )}
        <div className="input-outer-wrapper table-actions-dropdowns filter-margin">
          {props.onSearch && (
            <div className="dropdowns-inner-wrapper">
              <div className="p-input-icon-left search-input">
                <i className="pi pi-search" />
                <InputText
                  type="search"
                  value={search}
                  onInput={(e) => {
                    setSearch(e.target.value);
                    props.onSearch(e.target.value);
                  }}
                  placeholder={props.searchPlaceHolder || 'Search'}
                />
              </div>
            </div>
          )}
        </div>
        {props.experienceFilter && (
          <div className="input-outer-wrapper table-actions-dropdowns filter-margin">
            <div className="dropdowns-inner-wrapper">
              <div className="custom-form-group experienceFilter status-select-dropdown">
                <Button
                  variant="outlined"
                  aria-controls={props.open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={props.open ? 'true' : undefined}
                  onClick={props.onExperienceFilter}>
                  {props.label}
                  <span className="pi pi-chevron-down"></span>
                </Button>
              </div>
            </div>
          </div>
        )}
        {props.hrFilter && (
          <div className="table-actions-dropdowns filter-margin">
            <div className="dropdowns-inner-wrapper">
              <div className="custom-form-group status-select-dropdown">
                <MultiSelect
                  filter
                  optionLabel="name"
                  optionValue="id"
                  maxSelectedLabels={2}
                  value={props.selectedHrs}
                  options={props.hrs}
                  display="chip"
                  onChange={(e) => props.onFilter(e.value)}
                  placeholder="Select HRs"
                />
              </div>
            </div>
          </div>
        )}
        {props.dropdown && (
          <div className="table-actions-dropdowns filter-margin">
            <div className="dropdowns-inner-wrapper">
              <div className="custom-form-group status-select-dropdown">
                {props.isDropdownMultiSelect ? (
                  <MultiSelect
                    filter
                    value={props?.dropdownValue}
                    className="custom-dropdown"
                    options={props.dropdown}
                    panelClassName="custom-dropdown-panel"
                    onChange={(e) => {
                      props.onStatusFilter && props.onStatusFilter(e.value || '');
                    }}
                    maxSelectedLabels={2}
                    display="chip"
                    optionLabel="name"
                    optionValue="value"
                    {...(props.isdropdownGroup && { optionGroupLabel: 'label' })}
                    {...(props.isdropdownGroup && { optionGroupChildren: 'items' })}
                    placeholder={props.dropdownPlaceHolder || 'Status'}
                  />
                ) : (
                  <Dropdown
                    value={props?.dropdownValue}
                    className="custom-dropdown"
                    options={props.dropdown}
                    panelClassName="custom-dropdown-panel"
                    onChange={(e) => {
                      props.onStatusFilter && props.onStatusFilter(e.value || null);
                    }}
                    showClear
                    optionLabel="name"
                    placeholder={props.dropdownPlaceHolder || 'Status'}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {props.secondDropdown && (
          <div className="table-actions-dropdowns filter-margin">
            <div className="dropdowns-inner-wrapper">
              <div className="custom-form-group status-select-dropdown">
                {props.isSecondDropdownMultiSelect ? (
                  <MultiSelect
                    value={props?.secondDropdownValue}
                    className="custom-dropdown"
                    options={props.secondDropdown}
                    panelClassName="custom-dropdown-panel"
                    onChange={(e) => {
                      props.onSecondDropdownChange && props.onSecondDropdownChange(e.value || '');
                    }}
                    maxSelectedLabels={2}
                    display="chip"
                    optionLabel="name"
                    optionValue="value"
                    placeholder={props.secondDropdownPlaceHolder || 'Select...'}
                  />
                ) : (
                  <Dropdown
                    value={props?.secondDropdownValue}
                    className="custom-dropdown"
                    options={props.secondDropdown}
                    panelClassName="custom-dropdown-panel"
                    onChange={(e) => {
                      props.onSecondDropdownChange && props.onSecondDropdownChange(e.value || null);
                    }}
                    showClear
                    optionLabel="name"
                    placeholder={props.secondDropdownPlaceHolder || 'Select Duration'}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {props.thirdDropdown && (
          <div className="table-actions-dropdowns">
            <div className="dropdowns-inner-wrapper">
              <div className="custom-form-group status-select-dropdown">
                {props.isThirdDropdownMultiSelect ? (
                  <MultiSelect
                    value={props?.thirdDropdownValue}
                    className="custom-dropdown"
                    options={props.thirdDropdown}
                    panelClassName="custom-dropdown-panel"
                    onChange={(e) => {
                      props.onThirdDropdownChange && props.onThirdDropdownChange(e.value || '');
                    }}
                    maxSelectedLabels={2}
                    display="chip"
                    optionLabel="name"
                    optionValue="value"
                    placeholder={props.thirdDropdownPlaceHolder || 'Select...'}
                  />
                ) : (
                  <Dropdown
                    value={props?.thirdDropdownValue}
                    className="custom-dropdown"
                    options={props.thirdDropdown}
                    panelClassName="custom-dropdown-panel"
                    onChange={(e) => {
                      props.onThirdDropdownChange && props.onThirdDropdownChange(e.value || null);
                    }}
                    showClear
                    optionLabel="name"
                    placeholder={props.thirdDropdownPlaceHolder || 'Select...'}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {props.clearFilter && (
          <div className="table-actions-dropdowns">
            <div className="dropdowns-inner-wrapper">
              <div className="custom-form-group status-select-dropdown">
                <Button
                  variant="outlined"
                  aria-haspopup="true"
                  disabled={props.isClearFilterDisabled}
                  onClick={() => {
                    setSearch('');
                    props.clearFilterOnClick();
                  }}>
                  Clear Filter
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="table-actions-right flex">
        {/* Hide Export Functionality */}
        {/* {tableData.length > 0 && (
          <Button type="button" className="export-btn" onClick={exportExcel}>
            <i className="pi pi-fw pi-download" />
            EXPORT
          </Button>
        )} */}
        {props.onAdd && (
          <>
            <CustomeTooltip place="left"></CustomeTooltip>
            <Button
              data-tip={getUserTableName(tableName)}
              type="button"
              className="add-user-btn"
              onClick={props.onAdd}>
              <i className="pi pi-fw pi-plus" />
            </Button>
          </>
        )}
      </div>
    </div>
  );

  const onPage = (e) => {
    setTableLazyParams(e);
    const rowsPerPage = e.rows;
    setSelectedLimit(rowsPerPage);
    const pageNumber = e.page + 1;
    props.onPagination(pageNumber, rowsPerPage);
  };

  const onSort = (e) => {
    const fieldName = e.multiSortMeta[0].field;
    const sort = tableColumns.find((column) => column.field === fieldName);
    props.onSort(sort.sortBy);
  };

  return (
    <div className="white-box custom-table-wrapper p-0 overflow-hidden">
      <DataTable
        {...props}
        size="small"
        ref={tableRef}
        value={tableData}
        header={!props?.isHeaderDisable ? tableHeader : null}
        totalRecords={tableTotalRecords}
        paginator={true && !props.disablePaginator}
        rows={selectedLimit ? selectedLimit : 10}
        lazy
        first={tableLazyParams.first}
        onPage={onPage}
        onSort={onSort}
        showGridlines
        stripedRows
        responsive={true}
        rowsPerPageOptions={props.rowPerPageOption ? props.rowPerPageOption : PAGINATION_OPTIONS}
        selection={selectedLimit}
        onSelectionChange={(e) => setSelectedLimit(e.rows)}
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        sortMode="multiple"
        responsiveLayout="scroll">
        {tableColumns?.map((column) => {
          return (
            <Column
              sortable={column.sortBy ? true : false}
              key={column.field}
              field={column.field}
              header={column.header}
              className={column.class}
              {...(column.width && { style: { width: column.width } })}
              {...(column.expander && { expander: column.expander })}
            />
          );
        })}
      </DataTable>

      <Menu
        className="experience-filter-dropdown"
        id="basic-menu"
        anchorEl={props.anchorEl}
        open={props.open || false}
        onClose={props.handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}>
        <div style={{ display: 'flex', alignItems: 'center', padding: '8px 16px' }}>
          <TextField
            type="number"
            id={minExp}
            sx={{
              width: 75,
              marginRight: 1
            }}
            value={minExp}
            InputProps={{ inputProps: { min: 0 } }}
            size="small"
            onChange={(event) => {
              const value = event.target.value;
              // Allow only integer values or an empty string
              if (value === '' || (/^\d+$/.test(value) && !value.includes('.'))) {
                setMinExp(value);
              }
            }}
          />{' '}
          To
          <TextField
            type="number"
            id={maxExp}
            sx={{
              width: 75,
              marginRight: 1,
              marginLeft: 1
            }}
            inputProps={{ min: minExp }}
            error={maxExp ? Number(maxExp) < Number(minExp) : false}
            value={maxExp}
            size="small"
            onChange={(event) => {
              const value = event.target.value;
              // Allow only integer values or an empty string
              if (value === '' || (/^\d+$/.test(value) && !value.includes('.'))) {
                setMaxExp(value);
              }
            }}
          />
          <Button
            className="border-btn"
            style={{ marginRight: 8 }}
            onClick={() => {
              setMaxExp('');
              setMinExp('');
              props.handleYearExperienceFilter('', '');
            }}>
            Clear
          </Button>
          <Button
            disabled={!(minExp && maxExp && Number(maxExp) >= Number(minExp))}
            onClick={() => props.handleYearExperienceFilter(minExp, maxExp)}>
            Filter
          </Button>
        </div>
      </Menu>
    </div>
  );
};

CustomDataTable.defaultProps = {
  onAdd: null,
  onSort: null,
  onPagination: null,
  onSearch: null,
  pagination: null,
  dropdown: null,
  columns: [],
  data: [],
  exportData: [],
  name: null,
  hrFilter: false,
  totalRecords: 0
};

export default CustomDataTable;
