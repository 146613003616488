export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const INTERVIEWERS_LIST_REQUEST = 'INTERVIEWERS_LIST_REQUEST';
export const INTERVIEWERS_ADD_REQUEST = 'INTERVIEWERS_ADD_REQUEST';
export const INTERVIEWERS_DELETE_REQUEST = 'INTERVIEWERS_DELETE_REQUEST';
export const INTERVIEWERS_EDIT_REQUEST = 'INTERVIEWERS_EDIT_REQUEST';
export const HR_LIST_REQUEST = 'HR_LIST_REQUEST';
export const JOB_DESIGNATION_REQUEST = 'JOB_DESIGNATION_REQUEST';
export const ADD_JOB_DESIGNATION_REQUEST = 'ADD_JOB_DESIGNATION_REQUEST';
export const EDIT_JOB_DESIGNATION_REQUEST = 'EDIT_JOB_DESIGNATION_REQUEST';
export const DELETE_JOB_DESIGNATION_REQUEST = 'DELETE_JOB_DESIGNATION_REQUEST';
export const JOB_DESIGNATION_GET_DETAILS = 'JOB_DESIGNATION_GET_DETAILS';
export const ADD_HR_REQUEST = 'ADD_HR_REQUEST';
export const DELETE_HR_REQUEST = 'DELETE_HR_REQUEST';
export const HR_EDIT_REQUEST = 'HR_EDIT_REQUEST';
export const INTERVIEWER_INVITATION_REQUEST = 'INTERVIEWER_INVITATION_REQUEST';
export const INTERVIEWER_FEEDBACK_REQUEST = 'INTERVIEWER_FEEDBACK_REQUEST';
export const INTERVIEWER_SCHEDULE_REQUEST = 'INTERVIEWER_SCHEDULE_REQUEST';
export const INTERVIEW_SCHEDULE_LIST_REQUEST = 'INTERVIEW_SCHEDULE_LIST_REQUEST';
export const INTERVIEW_INVITATION_LIST_REQUEST = 'INTERVIEW_INVITATION_LIST_REQUEST';
export const INTERVIEW_FEEDBACK_LIST_REQUEST = 'INTERVIEW_FEEDBACK_LIST_REQUEST';
export const INVITATION_DETAILS_REQUEST = 'INVITATION_DETAILS_REQUEST';
export const INTERVIEW_INVITATION_STATUS = 'INTERVIEW_INVITATION_STATUS';
export const INTERVIEW_STATUS = 'INTERVIEW_STATUS';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const TECHNOLOGY_REQUEST = 'TECHNOLOGY_REQUEST';
export const ADD_TECHNOLOGY_REQUEST = 'ADD_TECHNOLOGY_REQUEST';
export const EDIT_TECHNOLOGY_REQUEST = 'EDIT_TECHNOLOGY_REQUEST';
export const DELETE_TECHNOLOGY_REQUEST = 'DELETE_TECHNOLOGY_REQUEST';
export const GET_TECHNOLOGY_DETAILS = 'GET_TECHNOLOGY_DETAILS';
export const CANDIDATE_LIST_REQUEST = 'CANDIDATE_LIST_REQUEST';
export const CANDIDATE_SEARCH_REQUEST = 'CANDIDATE_SEARCH_REQUEST';
export const CANDIDATE_ADD_REQUEST = 'CANDIDATE_ADD_REQUEST';
export const CANDIDATE_GET_DETAILS = 'CANDIDATE_GET_DETAILS';
export const CANDIDATE_EDIT_REQUEST = 'CANDIDATE_EDIT_REQUEST';
export const CANDIDATE_DELETE_REQUEST = 'CANDIDATE_DELETE_REQUEST';
export const CANDIDATE_NOTE_GET_DETAILS = 'CANDIDATE_NOTE_GET_DETAILS';
export const CANDIDATE_NOTE_ADD_REQUEST = 'CANDIDATE_NOTE_ADD_REQUEST';
export const CANDIDATE_NOTE_EDIT_REQUEST = 'CANDIDATE_NOTE_EDIT_REQUEST';
export const CANDIDATE_NOTE_DELETE_REQUEST = 'CANDIDATE_NOTE_DELETE_REQUEST';
export const CANDIDATE_DETAILS_REQUEST = 'CANDIDATE_DETAILS_REQUEST';
export const GET_JOBS = 'GET_JOBS';
export const ADD_JOB = 'ADD_JOB';
export const UPDATE_JOB = 'UPDATE_JOB';
export const GET_JOB_BY_ID = 'GET_JOB_BY_ID';
export const DELETE_JOB = 'DELETE_JOB';
export const GET_JOB_APPLICATIONS = 'GET_JOB_APPLICATIONS';
export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const INTERVIEW_SCHEDULE_REQUEST = 'INTERVIEW_SCHEDULE_REQUEST';
export const INTERVIEWER_INTERVIEW_DETAILS_REQUEST = 'INTERVIEWER_INTERVIEW_DETAILS_REQUEST';
export const INTERVIEW_DETAILS_REQUEST = 'INTERVIEW_DETAILS_REQUEST';
export const ALL_INTERVIEWERS_REQUEST = 'ALL_INTERVIEWERS_REQUEST';
export const INTERVIEWS_LIST_REQUEST = 'INTERVIEWS_LIST_REQUEST';
export const INTERVIEWS_DETAILS_REQUEST = 'INTERVIEWS_DETAILS_REQUEST';
export const INTERVIEWE_SCHEDULE_REQUEST = 'INTERVIEWE_SCHEDULE_REQUEST';
export const INTERVIEWS_ADD_REQUEST = 'INTERVIEWS_ADD_REQUEST';
export const INTERVIEW_GET_DETAILS = 'INTERVIEW_GET_DETAILS';
export const INTERVIEW_EDIT_REQUEST = 'INTERVIEW_EDIT_REQUEST';
export const EDIT_USER_DETAILS = 'EDIT_USER_DETAILS';
export const USER_STATUS = 'USER_STATUS';
export const HR_AVAILABLE_SCHEDULE_REQUEST = 'HR_AVAILABLE_SCHEDULE_REQUEST';
export const HR_SCHEDULE_INTERVIEW_REQUEST = 'HR_SCHEDULE_INTERVIEW_REQUEST';
export const INTERVIEWER_AVAILABLEILITY_REQUEST = 'INTERVIEWER_AVAILABLEILITY_REQUEST';
export const INTERVIEWER_AVAILABLEILITY_ADD_REQUEST = 'INTERVIEWER_AVAILABLEILITY_ADD_REQUEST';
export const CANDIDATE_REQUEST = 'CANDIDATE_REQUEST';
export const INTERVIEW_HISTORY_LIST_REQUEST = 'INTERVIEW_HISTORY_LIST_REQUEST';
export const ADMIN_DASHBOARD_COUNT_REQUEST = 'ADMIN_DASHBOARD_COUNT_REQUEST';
export const INTERVIEW_FEEDBACK = 'INTERVIEW_FEEDBACK';
export const USER_PROFILE_DETAILS = 'USER_PROFILE_DETAILS';
export const USER_PROFILE_EDIT_REQUEST = 'USER_PROFILE_EDIT_REQUEST';
export const USER_PASSWORD_CHANGE_REQUEST = 'USER_PASSWORD_CHANGE_REQUEST';
export const HR_JOB_DESIGNATION = 'HR_JOB_DESIGNATION';
export const HR_INTERVIEW_TECHNOLOGY = 'HR_INTERVIEW_TECHNOLOGY';
export const HR_JOB_DESIGNATION_BY_SEARCH = 'HR_JOB_DESIGNATION_BY_SEARCH';
export const HR_INTERVIEW_SEARCH_TECHNOLOGY = 'HR_INTERVIEW_SEARCH_TECHNOLOGY';
export const HR_INTERVIEWER_AVAILABLITY_BY_FILTER = 'HR_INTERVIEWER_AVAILABLITY_BY_FILTER';
export const HR_INTERVIEWERERS_LIST_BY_SEARCHNAME = 'HR_INTERVIEWERERS_LIST_BY_SEARCHNAME';
export const USER_PERMISSION_CHANGE_REQUEST = 'USER_PERMISSION_CHANGE_REQUEST';
export const USER_PERMISSION_REQUEST = 'USER_PERMISSION_REQUEST';
export const INTERVIEWS_ADD_ROUND_REQUEST = 'INTERVIEWS_ADD_ROUND_REQUEST';
export const INTERVIEWS_EDIT_ROUND_REQUEST = 'INTERVIEWS_EDIT_ROUND_REQUEST';
export const INTERVIEWS_EDIT_REQUEST = 'INTERVIEWS_EDIT_REQUEST';
export const INTERVIEWER_FEEDBACK_DETAILS_REQUEST = 'INTERVIEWER_FEEDBACK_DETAILS_REQUEST';
export const INTERVIEWS_TECHPARAMS_REQUEST = 'INTERVIEWS_TECHPARAMS_REQUEST';
export const GET_HR_FEEDBACK_HISTORY_LOGS = 'GET_HR_FEEDBACK_HISTORY_LOGS';
export const HR_SCHEDULE_INTERVIEW_COUNT = 'HR_SCHEDULE_INTERVIEW_COUNT';
export const CANDIDATE_EMAIL_CHECK_REQUEST = 'CANDIDATE_EMAIL_CHECK_REQUEST';
export const UPDATE_MOBILE_NUMBER = 'UPDATE_MOBILE_NUMBER';
export const ADMIN_INTERVIEW_ROUND_REQUEST = 'ADMIN_INTERVIEW_ROUND_REQUEST';
export const ADMIN_INTERVIEWERS_LIST_REQUEST = 'ADMIN_INTERVIEWERS_LIST_REQUEST';
export const ADMIN_INTERVIEW_ROUND_FILTER = 'ADMIN_INTERVIEW_ROUND_FILTER';
export const TECHNOLOGY_WITH_FILTER = 'TECHNOLOGY_WITH_FILTER';
export const ROUND_WITH_FILTER = 'ROUND_WITH_FILTER';
export const COMPLETE_INTERVIEW_FEEDBACK_REQUEST = 'COMPLETE_INTERVIEW_FEEDBACK_REQUEST';
export const GET_INTERVIEW_FEEDBACK_REQUEST = 'GET_INTERVIEW_FEEDBACK_REQUEST';
export const ADD_COMMON_PARAMS_REQUEST = 'ADD_COMMON_PARAMS_REQUEST';
export const COMMON_PARAMS_LIST_REQUEST = 'COMMON_PARAMS_LIST_REQUEST';
export const FILTER_COMMON_PARAMS_LIST_REQUEST = 'FILTER_COMMON_PARAMS_LIST_REQUEST';
export const GET_COMMON_PARAMS_DETAILS = 'GET_COMMON_PARAMS_DETAILS';
export const EDIT_COMMON_PARAMS_REQUEST = 'EDIT_COMMON_PARAMS_REQUEST';
export const DELETE_COMMON_PARAMS_REQUEST = 'DELETE_COMMON_PARAMS_REQUEST';
export const INTERVIEW_CANCEL_REQUEST = 'INTERVIEW_CANCEL_REQUEST';
export const INTERVIEW_ROUND_CANCEL_REQUEST = 'INTERVIEW_ROUND_CANCEL_REQUEST';
export const INTERVIEW_ROUND_SCHEDULE_DETAILS = 'INTERVIEW_ROUND_SCHEDULE_DETAILS';
export const CANCEL_CANDIDATE_INTERVIEW_ROUND = 'CANCEL_CANDIDATE_INTERVIEW_ROUND';
export const USER_EMAIL_OR_MOBILE_EXIST = 'USER_EMAIL_OR_MOBILE_EXIST';
export const USER_NOTIFICATIONS = 'USER_NOTIFICATIONS';
export const READ_NOTIFICATIONS = 'READ_NOTIFICATIONS';
export const JOB_DESCRIPTIONS_REQUEST = 'JOB_DESCRIPTIONS_REQUEST';
export const ADD_JOB_DESCRIPTION_REQUEST = 'ADD_JOB_DESCRIPTION_REQUEST';
export const EDIT_JOB_DESCRIPTION_REQUEST = 'EDIT_JOB_DESCRIPTION_REQUEST';
export const JOB_DESCRIPTION_GET_DETAILS = 'JOB_DESCRIPTION_GET_DETAILS';
export const DELETE_JOB_DESCRIPTION_REQUEST = 'DELETE_JOB_DESCRIPTION_REQUEST';
export const SEND_JOB_DESCRIPTION = 'SEND_JOB_DESCRIPTION';
