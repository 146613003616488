import { all } from 'redux-saga/effects';
import watcherAuthSaga from './auth';
import watcherInterviewersSaga from './interviewers';
import watcherHrListSaga from './hr';
import watcherCandidateSaga from './candidates';
import watcherUserDetailsSaga from './userDetails';
import watcherOptionsSaga from './showOptions';
import watcherInterviewsSaga from './interviews';
import watcherAdminDashboardCountSaga from './admin';
import watcherProfileDetailsSaga from './profileDetails';
import watcherDesignationsSaga from './designation';
import watcherDescriptionsSaga from './jobDescriptions';
import watcherTechnologySaga from './technology';
import watcherCommonParameterSaga from './CommonParameter';
import watcherJobsListSaga from './jobs';
import watcherJobApplicationListSaga from './jobApplication';

export default function* rootSaga() {
  yield all([
    watcherAuthSaga(),
    watcherInterviewersSaga(),
    watcherHrListSaga(),
    watcherCandidateSaga(),
    watcherUserDetailsSaga(),
    watcherOptionsSaga(),
    watcherInterviewsSaga(),
    watcherAdminDashboardCountSaga(),
    watcherProfileDetailsSaga(),
    watcherDesignationsSaga(),
    watcherDescriptionsSaga(),
    watcherTechnologySaga(),
    watcherCommonParameterSaga(),
    watcherJobsListSaga(),
    watcherJobApplicationListSaga()
  ]);
}
