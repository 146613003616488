import moment from 'moment';
import {
  CODE_QUALITY_STATUS,
  HR_OVERALL_FEEDBACK,
  INTERVIEW_STATUS_LIST,
  INTERVIEW_TYPE,
  InterviewType,
  OVERALL_GRADE_STATUS,
  ROLES,
  STATUS,
  STATUS_INTERVIEW,
  TABLE_NAME
} from '../constants/common';
import AvtarImage from '../pages/profile/AvtarImage';
import ReactTooltip from 'react-tooltip';
import { formatTime, getFormattedDateView } from './date';
import { Badge } from 'primereact/badge';
import { TECHNOLOGY_COLORS_OPTIONS } from '../constants/dropdownOptions';

export const getDataFromLocalstorage = (STORAGE_KEY) => {
  const data = localStorage.getItem(STORAGE_KEY);
  if (data) return JSON.parse(data);
  return null;
};

export const setDataInLocalstorage = (STORAGE_KEY, data) => {
  return localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
};

export const wordCapitalize = (word) => {
  return `${word?.charAt(0).toUpperCase()}${word?.slice(1)}`;
};

export const toLowerCaseText = (word) => {
  return `${word?.toLowerCase()}`;
};

export const getUserRole = (userRole) => {
  switch (userRole) {
    case ROLES.ADMIN:
      return 'Admin';
    case ROLES.INTERVIEWER:
      return 'Interviewer';
    case ROLES.HR:
      return 'HR';
    case ROLES.HR_AND_INTERVIEWER:
      return 'HR and Interviewer';
    case ROLES.CANDIDATE:
      return 'Candidate';
    default:
      return '';
  }
};

export const resumeValidation = (value) => {
  return (
    value &&
    (value.type === '.doc' ||
      value.type === '.docx' ||
      value.type === 'application/pdf' ||
      value.type === 'application/msword' ||
      value.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
  );
};

export const imageValidation = (value) => {
  return (
    value &&
    (value.type === 'image/jpeg' || value.type === 'image/jpg' || value.type === 'image/png')
  );
};

export const getUserTableName = (tableName) => {
  switch (tableName) {
    case TABLE_NAME.INTERVIEWERS:
      return 'Add New Interviewer';
    case TABLE_NAME.HR:
      return 'Add New HR';
    case TABLE_NAME.DESIGNATION:
      return 'Add New Designation';
    case TABLE_NAME.TECHNOLOGY:
      return 'Add New Technology';
    case TABLE_NAME.CANDIDATE:
      return 'Add New Candidate';
    case TABLE_NAME.INTERVIEW:
      return 'Add New Interview';
    case TABLE_NAME.COMMON_PRAMETERS:
      return 'Add New Parameter';
    default:
      return '';
  }
};

export const downloadPdf = async (pathBase, ID, FilePath) => {
  const response = await fetch(`${pathBase}/${ID}/${FilePath}`);
  const blob = await response.blob();
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = FilePath || 'Resume.pdf';
  a.click();

  URL.revokeObjectURL(url);
};

export function formatExperience(years, months) {
  // Convert years and months to numbers, defaulting to 0 if empty or invalid
  const formattedYears = parseInt(years) || 0;
  const formattedMonths = parseInt(months) || 0;

  // Calculate total years, ensuring it's at least 0
  const totalYears = `${formattedYears}.${formattedMonths}`;

  // If total years is 0, return "0 Y"
  return totalYears !== '0.0' ? `${totalYears} Y` : '0 Y';
}

export const renderHrAvatar = (id, imageName, firstName, lastName, isNameVisible = false) => {
  return (
    <>
      <ReactTooltip />
      {firstName && lastName ? (
        <div className="avtar-wrapper">
          <div className="hr-avatar" data-tip={`${firstName || ''} ${lastName}`}>
            {imageName ? (
              <img
                src={`${process.env.REACT_APP_API_ENDPOINT}/public/uploads/profile/${id}/${imageName
                  .split('/')
                  .map((p) => encodeURIComponent(p))
                  .join('/')}`}
                alt="profile"
                className="border-circle user-img"
              />
            ) : (
              <AvtarImage className="user-img" firstName={firstName} lastName={lastName} />
            )}
          </div>
          {isNameVisible && <div className="name">{`${firstName || ''} ${lastName}`}</div>}
        </div>
      ) : (
        '--'
      )}
    </>
  );
};

export const formatAvailabilityTime = (availabilityTime) => {
  if (!availabilityTime) return '';

  // Sort the availabilityTime array by start time
  const sortedAvailabilityTime = [...availabilityTime].sort((a, b) => {
    const startA = moment(a.StartTimeOfAvailability, 'HH:mm:ss');
    const startB = moment(b.StartTimeOfAvailability, 'HH:mm:ss');
    return startA - startB;
  });

  return sortedAvailabilityTime
    .map((slot) => {
      const startTime = moment(slot.StartTimeOfAvailability, 'HH:mm:ss').format('h:mm A');
      const endTime = moment(slot.EndTimeOfAvailability, 'HH:mm:ss').format('h:mm A');
      return `${startTime} - ${endTime}`;
    })
    .join(', ');
};

export const isDarkMode = () => {
  return localStorage.getItem('darkMode') === 'true';
};

export const normalizeObject = (obj) =>
  JSON.parse(
    JSON.stringify(obj, (key, value) =>
      value === '' || (Array.isArray(value) && value.length === 0) ? '' : value
    )
  );

export const addScript = (scriptId, src = '') => {
  const script = document.createElement('script');
  script.id = scriptId;
  script.src = src;
  script.async = true;
  document.body.appendChild(script);
};

export const removeScript = (scriptId = '') => {
  const script = document.getElementById(scriptId);
  if (script) {
    script.remove();
  }
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const setPreviousRoundDetails = (userDetails) => {
  const getResult = (code) => {
    const status = HR_OVERALL_FEEDBACK.find((status) => status.code === code);
    return status ? `${status.name}` : '--';
  };

  return userDetails.previousRoundData.reduce((acc, curr) => {
    let date = `${INTERVIEW_TYPE.filter((num) => num.code === curr?.InterviewRound).map(
      (type) => type.name
    )} : ${getFormattedDateView(curr.StartDateOfInterviewRound)} ${formatTime(
      curr.StartDateOfInterviewRound
    )}`;

    acc[date] = {
      'Start Date': `${getFormattedDateView(curr?.StartDateOfInterviewRound)} ${formatTime(
        curr?.StartDateOfInterviewRound || ''
      )} `,
      'Interview Type':
        `${INTERVIEW_TYPE.filter((num) => num.code === curr?.InterviewRound).map(
          (type) => type.name
        )}` || '--',
      Status: INTERVIEW_STATUS_LIST.filter((num) => num.code === curr.Status).map((type, index) => {
        return (
          <>
            <div className="center interview-status" key={index}>
              <Badge
                key={type.code}
                value={type.name}
                className={`white-space-nowrap ${
                  curr?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_SCHEDULE ||
                  curr?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_SCHEDULE ||
                  curr?.Status === STATUS_INTERVIEW.MANAGEMENT_SCHEDULE ||
                  curr?.Status === STATUS_INTERVIEW.PRACTICAL_SCHEDULE ||
                  curr?.Status === STATUS_INTERVIEW.HR_SCHEDULE
                    ? 'schedule-badge'
                    : curr?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_RESCHEDULE ||
                      curr?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_RESCHEDULE ||
                      curr?.Status === STATUS_INTERVIEW.MANAGEMENT_RESCHEDULE ||
                      curr?.Status === STATUS_INTERVIEW.HR_RESCHEDULE ||
                      curr?.Status === STATUS_INTERVIEW.PRACTICAL_RESCHEDULE
                    ? 'reschedule-badge'
                    : curr?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_COMPLETE ||
                      curr?.Status === STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_COMPLETE ||
                      curr?.Status === STATUS_INTERVIEW.MANAGEMENT_COMPLETE ||
                      curr?.Status === STATUS_INTERVIEW.PRACTICAL_COMPLETE ||
                      curr?.Status === STATUS_INTERVIEW.HR_COMPLETE
                    ? 'complete-badge'
                    : curr?.Status === STATUS_INTERVIEW.CANCELLED
                    ? 'cancel-badge'
                    : 'pending-badge'
                }`}
              />
            </div>
          </>
        );
      }),
      ...((curr?.InterviewRound === InterviewType['Technical Interview - 1'] ||
        curr?.InterviewRound === InterviewType['Technical Interview - 2'] ||
        curr?.InterviewRound === InterviewType['Management Round']) &&
        [
          STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_COMPLETE,
          STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_COMPLETE,
          STATUS_INTERVIEW.MANAGEMENT_COMPLETE
        ].indexOf(curr?.Status) !== -1 && {
          'Attitude Grade': curr?.Ratings?.TechnicalAttitude
            ? OVERALL_GRADE_STATUS[curr?.Ratings?.TechnicalAttitude]
            : '--',
          'Communication Grade': curr?.Ratings?.TechnicalCommunication
            ? OVERALL_GRADE_STATUS[curr?.Ratings?.TechnicalCommunication]
            : '--'
        }),
      ...(curr?.InterviewRound === InterviewType.Practical &&
        curr?.Status === STATUS_INTERVIEW.PRACTICAL_COMPLETE && {
          'Practical Definition': curr.Ratings?.PracticalDefinition || '--',
          'Technology Used': curr.Ratings?.PracticalPerformedUsing
            ? curr.Ratings?.PracticalPerformedUsing?.join(', ')
            : '--',
          'Spend Time (Min)': curr.Ratings?.SpendTime || '--',
          'Code Review': curr?.Ratings?.CodeReview
            ? OVERALL_GRADE_STATUS[curr?.Ratings?.CodeReview]
            : '--',
          'Code Quality': curr?.Ratings?.CodeQuality
            ? CODE_QUALITY_STATUS[curr?.Ratings?.CodeQuality]
            : '--',
          Communication: curr?.Ratings?.PracticalCommunication
            ? OVERALL_GRADE_STATUS[curr?.Ratings?.PracticalCommunication]
            : '--',
          'Can Do': curr?.Ratings?.CanDoAttitude
            ? OVERALL_GRADE_STATUS[curr?.Ratings?.CanDoAttitude]
            : '--',
          'Task completion (%)': curr?.Ratings?.TaskCompletion || '--'
        }),
      ...(curr?.InterviewRound === InterviewType['HR Round'] &&
        curr?.Status === STATUS_INTERVIEW.HR_COMPLETE && {
          Designation: curr.Ratings?.Designation || '--',
          'Average Annual Salary': curr.Ratings?.Package || '--',
          'Candidate Salary Expectation': curr.Ratings?.CandidateExpectations || '--'
        }),
      ...([
        STATUS_INTERVIEW.TECHNICAL_INTERVIEW_1_COMPLETE,
        STATUS_INTERVIEW.TECHNICAL_INTERVIEW_2_COMPLETE,
        STATUS_INTERVIEW.MANAGEMENT_COMPLETE,
        STATUS_INTERVIEW.PRACTICAL_COMPLETE,
        STATUS_INTERVIEW.HR_COMPLETE
      ].indexOf(curr?.Status) !== -1 && {
        'Overall grade': curr?.Ratings?.OverAllGrades
          ? OVERALL_GRADE_STATUS[curr?.Ratings?.OverAllGrades]
          : '--',
        Result: getResult(curr.Ratings?.Result),
        Note: (
          <div
            dangerouslySetInnerHTML={{
              __html: curr?.InterviewRoundFeedback
                ? curr?.InterviewRoundFeedback.replace(/\r\n|\n/g, '<br />')
                : '--'
            }}
          />
        )
      })
    };
    return acc;
  }, {});
};

export function filterFeedbackParams(feedbackParameterIds, feedbackParamsOptions = []) {
  let singleParams = [];
  let groupParams = [];

  if (feedbackParameterIds?.length) {
    feedbackParameterIds.forEach((id) => {
      let param = feedbackParamsOptions.find((item) => item.code === id);
      if (param && !param.disabled) {
        if (param.childParams?.length) {
          groupParams.push({
            childParams: param.childParams.map((pr) => pr.id),
            parentId: param.code
          });
        } else if (!singleParams.includes(id)) {
          singleParams.push(id);
        }
      }
    });
  }

  return { singleParams, groupParams };
}

export function isEqual(a, b) {
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);

  if (aKeys.length !== bKeys.length) {
    return false;
  }

  for (let key of aKeys) {
    if (a[key] instanceof Date && b[key] instanceof Date) {
      if (a[key].getTime() !== b[key].getTime()) {
        return false;
      }
    } else if (a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}

export function checkIsOnlyFeedbackUpdate(oldData, updatedData, startDate) {
  const {
    InterviewRoundInvitations,
    InterviewRound,
    InterviewRoundMode,
    StartDateOfInterviewRound,
    InterviewerLimit,
    InterviewDuration,
    Description
  } = oldData;

  const interviewersIDs = InterviewRoundInvitations.filter((interviewerDetails) => {
    if (
      interviewerDetails?.Status === STATUS.ACCEPT ||
      interviewerDetails?.Status === STATUS.PENDING
    )
      return true;
  }).map((interviewerDetail) => {
    return interviewerDetail?.Invited?.ID;
  });

  let backupDate = {
    interviewerID: interviewersIDs?.length ? interviewersIDs.join(',') : '',
    interviewType: InterviewRound,
    interviewRoundMode: InterviewRoundMode,
    interviewStartDateTime: new Date(StartDateOfInterviewRound),
    interviewerLimit: InterviewerLimit.toString(),
    interviewDuration: InterviewDuration,
    description: Description
  };

  let newData = {
    interviewerID: updatedData.interviewerID?.length ? updatedData.interviewerID.join(',') : '',
    interviewType: updatedData.interviewType,
    interviewRoundMode: updatedData.interviewRoundMode,
    interviewStartDateTime: startDate,
    interviewerLimit: updatedData.interviewerLimit,
    interviewDuration: updatedData.interviewDuration,
    description: updatedData.description
  };

  return isEqual(backupDate, newData);
}

export function getTechnologyColorByIndex(index) {
  const length = TECHNOLOGY_COLORS_OPTIONS.length;

  // Reset index if it exceeds the array length
  if (index >= length) {
    index = index % length; // Reset to a valid index
  }

  return TECHNOLOGY_COLORS_OPTIONS[index];
}

export const openResumeLink = (link) => window.open(link, '_blank');

export function isValidExperienceFormat(value) {
  if (value == null || value === '') return true; // Allow empty input

  const [years, months] = value.toString().split('.');
  const formattedMonths = months?.length < 2 ? `${months}0` : `${months}`;

  console.log(years);
  const number = parseInt(formattedMonths, 10); // Convert the string to a number
  const validMonths = isNaN(number) ? 0 : number;
  return validMonths < 12;
}
