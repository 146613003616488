import api from '../../utils/axios';

// Constants
import { API_ROUTES } from '../../constants/apiRoutes';

export const getJobsListApi = async ({ pageNumber, searchValue, sortBy, orderBy, size }) => {
  try {
    const response = await api.get(
      `${API_ROUTES.HR_JOBS}?page=${pageNumber}&searchName=${searchValue}&sortBy=${sortBy}&orderBy=${orderBy}&size=${size}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getJobByIdApi = async (ID) => {
  try {
    const response = await api.get(`${API_ROUTES.HR_JOBS}/${ID}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const addJobApi = async (job) => {
  try {
    const response = await api.post(API_ROUTES.HR_JOBS, job);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const editJobApi = async (details) => {
  try {
    const response = await api.put(`${API_ROUTES.HR_JOBS}/${details.ID}`, details.payload);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteJobApi = async (ID) => {
  try {
    const response = await api.delete(`${API_ROUTES.HR_JOBS}/${ID}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
