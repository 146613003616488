import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jobs: [],
  searchData: [],
  jobDetails: {},
  pagination: {}
};

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    jobsDetails(state, action) {
      return {
        ...state,
        ...action.payload,
        isDeleted: false,
        isUpdated: false,
        isAdded: false
      };
    },
    editJobDetails(state, action) {
      return { ...state, ...action.payload };
    },
    deleteJob(state) {
      return { ...state, isDeleted: true };
    },
    updateData(state) {
      return { ...state, isUpdated: true, isAdded: true };
    }
  }
});

export const { jobsDetails, editJobDetails, deleteJob, updateData } = jobsSlice.actions;
export default jobsSlice.reducer;
