import { useCallback, useEffect, useState } from 'react';

// Components
import CustomDataTable from '../../components/DataTable';
import { ResumeButton } from '../../components/ActionButtons';
import UserDetails from '../../components/popup/UserDetails';

// Constants
import {
  CANDIDATE_SOURCE_TYPE,
  DEBOUNCE_TIMEOUT,
  INITIAL_PAGE_NUMBER,
  INITIAL_SIZE,
  TABLE_NAME
} from '../../constants/common';
import { JOBS_APPLICATION_COLUMNS } from '../../constants/tableColumns';

// Utility Functions
import { debounce } from '../../utils/debounce';
import { getJobApplicationsList } from '../../redux/actions/jobApplication';
import {
  formatExperience,
  openResumeLink,
  renderHrAvatar,
  wordCapitalize
} from '../../utils/common';
import { candidateDetailsAction } from '../../redux/actions/userDetails';

// Utility Packages
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const jobApplicationsTableStates = {
  searchValue: '',
  pageNumber: INITIAL_PAGE_NUMBER,
  size: INITIAL_SIZE,
  fromHr: true,
  sortBy: 'DateModified',
  orderBy: 'desc'
};

const profilePathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/profile`;

const AppliedJobs = () => {
  const dispatch = useDispatch();
  const jobApplications = useSelector((state) => state.jobApplications);
  const { userDetails } = useSelector((state) => state);

  // State
  const [tableStates, setTableStates] = useState(jobApplicationsTableStates);
  const [jobApplicationsData, setJobApplicationsData] = useState([]);
  const [isHoveredImage, setIsHoveredImage] = useState({});
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [expandedRows, setExpandedRows] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState([]);
  const [candidateId, setCandidateId] = useState(null);

  useEffect(() => {
    dispatch(getJobApplicationsList(tableStates));
  }, [
    tableStates,
    jobApplications?.isDeleted,
    jobApplications?.isUpdated,
    jobApplications?.isAdded
  ]);

  const onTogglePopup = () => setShowPopup((prevState) => !prevState);

  const modifyUserDetails = useCallback(() => {
    if (userDetails?.ID) {
      // Display -- if the candidate mobile number is not provided.
      let mobileValue = '--';
      if (userDetails?.Mobile) {
        mobileValue = userDetails?.Mobile;
      }
      if (mobileValue && mobileValue === 'null') {
        mobileValue = '--';
      }
      const candidateDetailsData = [
        {
          title: 'Name',
          value: `${wordCapitalize(userDetails?.FirstName) || ''} 
              ${wordCapitalize(userDetails?.LastName) || ''}`
        },
        { title: 'Gender', value: userDetails?.Gender === 1 ? 'Male' : 'Female' || '' },
        {
          title: 'Experience',
          value: formatExperience(userDetails?.YearsOfExperience, userDetails?.MonthsOfExperience)
        },
        {
          title: 'Email Address',
          value: (
            <div className="center">
              <span>{userDetails?.Email || '--'}</span>
              {userDetails?.Email && (
                <>
                  <i
                    id={userDetails?.ID}
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={userDetails?.ID}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={userDetails?.ID}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.Email);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Mobile',
          value: (
            <div className="center">
              <span>{mobileValue || '--'}</span>
              {userDetails?.Mobile && (
                <>
                  <i
                    id={mobileValue}
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={mobileValue}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={mobileValue}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(mobileValue);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Second Mobile',
          value: (
            <div className="center">
              <span>{userDetails?.SecondaryMobile || '--'}</span>
              {userDetails?.SecondaryMobile && (
                <>
                  <i
                    id={userDetails?.SecondaryMobile}
                    data-tip="Copied"
                    data-event="click focus"
                    data-for={userDetails?.SecondaryMobile}
                    className="pi pi-fw pi-copy ml-1 copy-icon"
                  />
                  <ReactTooltip
                    id={userDetails?.SecondaryMobile}
                    globalEventOff="click"
                    afterShow={() => {
                      navigator.clipboard.writeText(userDetails?.SecondaryMobile);
                      setTimeout(() => {
                        ReactTooltip.hide();
                      }, 500);
                    }}
                  />
                </>
              )}
            </div>
          )
        },
        {
          title: 'Current Company',
          value: userDetails?.CurrentCompany || ''
        },
        {
          title: 'Technology',
          value:
            userDetails.CandidateTechnology && userDetails.CandidateTechnology.length > 0
              ? userDetails.CandidateTechnology?.map((tech) => tech.Technology).join(', ')
              : ''
        },
        {
          title: 'Current CTC',
          value: userDetails?.CurrentCTC ? `${userDetails?.CurrentCTC} LPA` : ''
        },
        {
          title: 'Candidate Location',
          value: userDetails?.Address || ''
        },
        {
          title: 'Candidate Source',
          value: CANDIDATE_SOURCE_TYPE.find((type) => type.code === userDetails?.CandidateSource)
            ?.name
        }
      ];
      // Only add the candidate reference name if candidate provided source is reference.
      if (
        userDetails?.CandidateSource ===
        CANDIDATE_SOURCE_TYPE[CANDIDATE_SOURCE_TYPE.length - 1].code
      ) {
        candidateDetailsData.push({
          title: 'Employee Ref Name',
          value: userDetails?.EmployeeReferenceName || ''
        });
      }
      candidateDetailsData.push(
        {
          title: 'Created By',
          value: renderHrAvatar(
            userDetails?.ReportsToUser?.ID,
            userDetails?.ReportsToUser?.UserProfile,
            userDetails?.ReportsToUser?.FirstName,
            userDetails?.ReportsToUser?.LastName,
            true
          )
        },
        {
          title: 'Last Updated Time',
          value: userDetails?.DateModified ? moment(userDetails?.DateModified).format('lll') : null
        }
      );
      setCandidateDetails(candidateDetailsData);
    }
  }, [userDetails]);

  useEffect(() => {
    modifyUserDetails();
  }, [userDetails, modifyUserDetails]);

  const handlePagination = (pageNumber, size) =>
    setTableStates((prevState) => {
      return {
        ...prevState,
        pageNumber,
        size
      };
    });

  const handleSearch = (searchValue) => {
    setTableStates((prevState) => {
      return {
        ...prevState,
        searchValue
      };
    });
  };

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const handleView = (ID) => {
    if (ID) {
      onTogglePopup();
      setCandidateId(ID);
    }
  };

  useEffect(() => {
    const modifiedJobs = jobApplications?.jobApplications?.map((candidate, index) => {
      return {
        ...candidate,
        ...candidate?.JobDetails,
        AppliedJobs: candidate?.AppliedJobs.map((j) => ({
          ...j,
          NoticePeriod: j?.NoticePeriod ? `${j.NoticePeriod} Months` : '',
          TotalExperience: j?.TotalExperience ? `${j.TotalExperience} Y` : '',
          CurrentCTC: j?.CurrentCTC ? `${j.CurrentCTC} LPA` : '',
          ExpectedCTC: j?.ExpectedCTC ? `${j.ExpectedCTC} LPA` : '',
          Resume: {
            ...j.Resume,
            FilePath: (
              <div className="flex">
                {j.Resume?.FilePath && (
                  <>
                    <ResumeButton
                      onClick={() => {
                        openResumeLink(j.Resume?.FilePath);
                      }}
                    />
                  </>
                )}
              </div>
            )
          }
        })),
        CandidateName: (
          <div className="flex" style={{ position: 'relative' }}>
            <Link
              to="#"
              className="table-view-popup-link"
              onClick={() => handleView(candidate?.ID)}
              title={`Mobile : ${candidate.Mobile || '--'}`}>
              {`${wordCapitalize(candidate.FirstName)} ${wordCapitalize(candidate.LastName)}`}
            </Link>
            {candidate?.UserProfile && candidate?.ID && (
              <div
                onMouseEnter={() => {
                  if (candidate?.UserProfile && candidate?.ID) {
                    let hoveredImage = { ...isHoveredImage, [candidate?.ID]: true };
                    setIsHoveredImage(hoveredImage);
                  }
                }}
                onMouseLeave={() => {
                  let hoveredImage = { ...isHoveredImage, [candidate?.ID]: false };
                  setIsHoveredImage(hoveredImage);
                }}
                onMouseMove={handleMouseMove}>
                <i
                  onClick={() =>
                    openResumeLink(`${profilePathBase}/${candidate?.ID}/${candidate?.UserProfile}`)
                  }
                  className="pi pi-fw pi-eye"
                />
                {isHoveredImage[candidate?.ID] && (
                  <div
                    className="image-preview"
                    key={index}
                    style={{
                      top: `${mousePosition.y + 10}px`,
                      left: `${mousePosition.x + 10}px`
                    }}>
                    <img
                      src={`${profilePathBase}/${candidate?.ID}/${candidate?.UserProfile}`}
                      alt="Preview"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ),
        Experience: formatExperience(candidate.YearsOfExperience, candidate?.MonthsOfExperience),
        Location: candidate?.Address || '--'
      };
    });
    setJobApplicationsData(modifiedJobs);
  }, [jobApplications, isHoveredImage]);

  useEffect(() => {
    candidateId && dispatch(candidateDetailsAction(candidateId));
  }, [dispatch, candidateId, userDetails?.isCandidateUpdated]);

  const rowExpansionTemplate = (data) => {
    return (
      <div className="applied-job py-3">
        <DataTable value={data.AppliedJobs}>
          <Column header="Job Title" field="JobDetails.JobTitle" className="name-col" />
          <Column header="Total Ex." field="TotalExperience" />
          <Column header="Current Company" field="CurrentCompany" />
          <Column header="Current CTC" field="CurrentCTC" />
          <Column header="Expected CTC" field="ExpectedCTC" />
          <Column header="Notice Period" field="NoticePeriod" />
          <Column header="Highest Qualification" field="HighestQualification.Course" />
          <Column header="Resume" field="Resume.FilePath" />
        </DataTable>
      </div>
    );
  };

  return (
    <>
      <UserDetails
        header="Candidate Details"
        data={candidateDetails}
        onHide={onTogglePopup}
        show={showPopup}
      />

      <CustomDataTable
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        name={TABLE_NAME.APPLIED_JOBS}
        searchPlaceHolder="Search by Title"
        totalRecords={jobApplications?.pagination?.totalRecords || 0}
        onPagination={handlePagination}
        onSearch={debounce(handleSearch, DEBOUNCE_TIMEOUT)}
        data={jobApplicationsData}
        columns={JOBS_APPLICATION_COLUMNS}
      />
    </>
  );
};

export default AppliedJobs;
