import { call, put, takeEvery } from 'redux-saga/effects';
import { RESPONSE_STATUS_CODES } from '../../constants/common';
import { GET_JOB_APPLICATIONS } from '../../constants/redux';
import { getJobApplicationsListApi } from '../apis/jobApplication';
import { jobApplicationsDetails } from '../slices/jobApplication';

function* JobApplicationsListSaga(action) {
  try {
    const response = yield call(getJobApplicationsListApi, { ...action.payload });
    if (response?.status === RESPONSE_STATUS_CODES.OK) {
      const result = response.data.data;
      const jobApplications = result.data;
      const pagination = result.pagination;
      yield put(jobApplicationsDetails({ jobApplications, pagination }));
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* watcherJobsListSaga() {
  yield takeEvery(GET_JOB_APPLICATIONS, JobApplicationsListSaga);
}
