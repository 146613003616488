import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jobApplications: [],
  pagination: {}
};

const jobApplicationsSlice = createSlice({
  name: 'jobApplications',
  initialState,
  reducers: {
    jobApplicationsDetails(state, action) {
      return {
        ...state,
        ...action.payload,
        isDeleted: false,
        isUpdated: false,
        isAdded: false
      };
    }
  }
});

export const { jobApplicationsDetails } = jobApplicationsSlice.actions;
export default jobApplicationsSlice.reducer;
