import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

// Pages & Components
import Home from './components/Home';
import Protected from './components/Protected';
import Login from './pages/auth/Login';
import NotAllowed from './pages/auth/NotAllowed';
import NotFound from './pages/auth/NotFound';
import AdminDashboard from './pages/AdminDashboard';
import HRs from './pages/hr/HRs';
import AddEditHR from './pages/hr/AddEditHR';
import InterviewersList from './pages/interviewers/InterviewersList';
import AddEditInterviewer from './pages/interviewers/AddEditInterviewer';
import Candidates from './pages/candidates/Candidates';
import Interviews from './pages/interviews/Interviews';
import InterviewerDashboard from './pages/interviewers/InterviewerDashboard';
import InterviewInvitation from './pages/interviewers/InterviewInvitation';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import AddEditCandidates from './pages/candidates/AddEditCandidates';
import InterviewSchedule from './pages/interviewers/InterviewSchedule';
import Schedule from './pages/hr/Schedule';
import InterviewerAvailability from './pages/interviewers/InterviewerAvailability';
import AddEditInterviews from './pages/interviews/AddEditInterviews';
import HRDashboard from './pages/hr/HRDashboard';
import Footer from './components/Footer';
import InterviewHistory from './pages/interviewers/InterviewHistory';
import Technology from './pages/technology/Technologies';
import AddEditTechnology from './pages/technology/AddEditTechnology';
import UserProfile from './pages/profile/UserProfile';
import JobDesignation from './pages/designations/JobDesignation';
import AddEditJobDesignation from './pages/designations/AddEditJobDesignation';
import Notification from './components/Notification';
import Loader from './components/Loader';
import JobDescriptions from './pages/jobDescriptions/JobDescriptions';
import Jobs from './pages/jobs/Jobs';
import AppliedJobs from './pages/jobs/AppliedJobs';

// Constants
import {
  ANCHOR_ID,
  JIRA_TOOL_SRC,
  PERMISSIONS,
  ROLES,
  ROUTES,
  SCRIPT_ID
} from './constants/common';

// Styles
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.scss';
import 'react-datetime/css/react-datetime.css';
import 'react-datepicker/dist/react-datepicker.css';

// Swiper css
import 'swiper/css/bundle';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { setPermissionAndToken } from './redux/slices/notification';

// Api-Services
import { notificationSendTokenApi } from './redux/apis/notification';

// Firebase
import { requestForToken } from './firebase/messaging';
import InterviewRounds from './pages/interviews/InterviewRounds';
import AddEditInterviewRound from './pages/interviews/AddEditInterviewRound';
import CommonParameters from './pages/commonParams/CommonParams';
import AdminCandidatesList from './pages/candidates/AdminCandidatesList';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getUserNotificationsAction } from './redux/actions/profileDetails';
import EnableNotification from './components/popup/EnableNotification';
import { addScript, removeScript } from './utils/common';

const App = () => {
  const dispatch = useDispatch();
  const { isLogged, userType, isRegisteredUser } = useSelector((state) => state.auth);
  const { loading } = useSelector((state) => state.hrs);
  const { isLoading } = useSelector((state) => state.apiStatus);
  const { mode } = useSelector((state) => state.darkMode);
  const { UserPermissions } = useSelector((state) => state.profileDetails);
  const [isAllowNotificationPermission, setIsAllowNotificationPermission] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (mode) {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.remove('dark-theme');
    }
  }, [mode]);

  useEffect(() => {
    if (isLogged && isRegisteredUser) {
      requestForToken()
        .then((permissionAndToken) => {
          dispatch(setPermissionAndToken(permissionAndToken));
          if (permissionAndToken?.permission === PERMISSIONS.GRANTED && permissionAndToken?.token) {
            notificationSendTokenApi(permissionAndToken?.token);
          }
          if (PERMISSIONS.GRANTED !== permissionAndToken.permission) {
            setIsAllowNotificationPermission(true);
          }
        })
        .catch((err) => console.error(err));
    }
    if (isLogged && isRegisteredUser && userType !== ROLES.ADMIN) {
      dispatch(getUserNotificationsAction());
    }
  }, [isLogged]);

  useEffect(() => {
    if (isLogged) {
      addScript(SCRIPT_ID, JIRA_TOOL_SRC);
    } else {
      // Remove the script tag when the user logs out
      removeScript(SCRIPT_ID);
      removeScript(ANCHOR_ID);
    }

    // Cleanup on component unmount
    return () => {
      removeScript(SCRIPT_ID);
      removeScript(ANCHOR_ID);
    };
  }, [isLogged]);

  return (
    <>
      {isAllowNotificationPermission && UserPermissions?.IsNotificationEnable && (
        <EnableNotification
          show={isAllowNotificationPermission}
          onHide={() => setIsAllowNotificationPermission(false)}
        />
      )}
      <Notification />
      {isLoading && !loading && <Loader />}
      <Routes location={location} key={location.pathname}>
        <Route path={ROUTES.HOME} element={<Home />} exact />
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
        <Route
          element={
            <>
              <Protected userRole={ROLES.ADMIN || ROLES.HR || ROLES.INTERVIEWER} isLogged={true} />
              <Footer />
            </>
          }></Route>
        <Route
          element={
            <>
              <Protected userRole={ROLES.ADMIN} />
              <Footer />
            </>
          }>
          <Route path={ROUTES.ADMIN.DASHBOARD} element={<AdminDashboard />} />
          <Route path={ROUTES.ADMIN.USER_PROFILE} element={<UserProfile />} />
          <Route path={ROUTES.ADMIN.INTERVIEWER_ADD} element={<AddEditInterviewer />} />
          <Route path={ROUTES.ADMIN.INTERVIEWERS} element={<InterviewersList />} />
          <Route path={ROUTES.ADMIN.INTERVIEWER_EDIT} element={<AddEditInterviewer />} />
          <Route path={ROUTES.ADMIN.HR_ADD} element={<AddEditHR />} />
          <Route path={ROUTES.ADMIN.HRS} element={<HRs />} />
          <Route path={ROUTES.ADMIN.HR_EDIT} element={<AddEditHR />} />
          <Route path={ROUTES.ADMIN.CANDIDATES} element={<AdminCandidatesList />} />
          <Route path={ROUTES.ADMIN.DESIGNATION} element={<JobDesignation />} />
          <Route path={ROUTES.ADMIN.DESIGNATION_ADD} element={<AddEditJobDesignation />} />
          <Route path={ROUTES.ADMIN.DESIGNATION_EDIT} element={<AddEditJobDesignation />} />
          <Route path={ROUTES.ADMIN.TECHNOLOGY} element={<Technology />} />
          <Route path={ROUTES.ADMIN.TECHNOLOGY_ADD} element={<AddEditTechnology />} />
          <Route path={ROUTES.ADMIN.TECHNOLOGY_EDIT} element={<AddEditTechnology />} />
          <Route path={ROUTES.ADMIN.DESCRIPTIONS} element={<JobDescriptions />} />
          <Route path={ROUTES.ADMIN.COMMON_PARAMETERS} element={<CommonParameters />} />
        </Route>
        <Route
          element={
            <>
              <Protected userRole={[ROLES.HR, ROLES.HR_AND_INTERVIEWER]} />
              <Footer />
            </>
          }>
          <Route path={ROUTES.HR.DASHBOARD} element={<HRDashboard />} />
          <Route path={ROUTES.HR.USER_PROFILE} element={<UserProfile />} />
          <Route path={ROUTES.HR.INTERVIEWS} element={<Interviews />} />
          <Route path={ROUTES.HR.INTERVIEW_ROUNDS} element={<InterviewRounds />} />
          <Route path={ROUTES.HR.INTERVIEWS_ADD} element={<AddEditInterviews />} />
          <Route path={ROUTES.HR.INTERVIEWS_EDIT} element={<AddEditInterviews />} />
          <Route path={ROUTES.HR.INTERVIEWS_ROUND_ADD_ID} element={<AddEditInterviewRound />} />
          <Route path={ROUTES.HR.INTERVIEWS_ROUND_EDIT_ID} element={<AddEditInterviewRound />} />
          <Route path={ROUTES.HR.CANDIDATES} element={<Candidates />} />
          <Route path={ROUTES.HR.JOBS} element={<Jobs />} />
          <Route path={ROUTES.HR.APPLIED_JOBS} element={<AppliedJobs />} />
          <Route path={ROUTES.HR.CANDIDATES_ADD} element={<AddEditCandidates />} />
          <Route path={ROUTES.HR.CANDIDATES_EDIT} element={<AddEditCandidates />} />
          <Route path={ROUTES.HR.INTERVIEW_SCHEDULE} element={<Schedule />} />
        </Route>
        <Route
          element={
            <>
              <Protected userRole={[ROLES.INTERVIEWER, ROLES.HR_AND_INTERVIEWER]} />
              <Footer />
            </>
          }>
          <Route path={ROUTES.INTERVIEWER.DASHBOARD} element={<InterviewerDashboard />} />
          <Route path={ROUTES.INTERVIEWER.USER_PROFILE} element={<UserProfile />} />
          <Route path={ROUTES.INTERVIEWER.INTERVIEW_SCHEDULED} element={<InterviewSchedule />} />
          <Route path={ROUTES.INTERVIEWER.INTERVIEW_INVITATION} element={<InterviewInvitation />} />
          <Route path={ROUTES.INTERVIEWER.INTERVIEW_HISTORY} element={<InterviewHistory />} />
          <Route path={ROUTES.INTERVIEWER.AVAILABILITY} element={<InterviewerAvailability />} />
        </Route>
        <Route path={ROUTES.NOT_ALLOWED} element={<NotAllowed />} />
        <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
