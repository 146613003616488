import { ADD_JOB, DELETE_JOB, GET_JOB_BY_ID, GET_JOBS, UPDATE_JOB } from '../../constants/redux';

export const getJobsList = (payload) => {
  return {
    type: GET_JOBS,
    payload
  };
};

export const addJob = (job) => {
  return {
    type: ADD_JOB,
    payload: job
  };
};

export const editJob = (Details) => {
  return {
    type: UPDATE_JOB,
    payload: Details
  };
};

export const getJobById = (ID) => {
  return {
    type: GET_JOB_BY_ID,
    payload: ID
  };
};

export const deleteJob = (ID) => {
  return {
    type: DELETE_JOB,
    payload: ID
  };
};
