import React, { useEffect, useRef, useState } from 'react';

// Constants
import { BUTTON_TYPES, LABEL_TYPES, TINY_MCE_PLUGINS } from '../../constants/common';
import {
  imageValidation,
  isDarkMode,
  isValidExperienceFormat,
  openResumeLink
} from '../../utils/common';

// Components
import { Dialog } from 'primereact/dialog';
import classNames from 'classnames';
import Label from '../../components/Label';
import CustomButton from '../../components/CustomButton';

// Utility Functions
import { onSuggestSkils } from '../../utils/pageUtils';
import { addJob, editJob, getJobById } from '../../redux/actions/jobs';
import { updateData } from '../../redux/slices/jobs';
import { apiStatusClear } from '../../redux/slices/apiStatus';

// Utility Packages
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Editor } from '@tinymce/tinymce-react';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { InputNumber } from 'primereact/inputnumber';
import { useDispatch, useSelector } from 'react-redux';
import { ResumeButton } from '../../components/ActionButtons';

const imagePathBase = `${process.env.REACT_APP_API_ENDPOINT}/public/uploads/job`;

export default function AddEditJob(props) {
  const { onHide, ID, show } = props;
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const imageRef = useRef(null);

  const { isSucceed } = useSelector((state) => state.apiStatus);
  const { jobDetails } = useSelector((state) => state.jobs);

  const [skilSuggestion, setSkilSuggestion] = useState([]);
  const [isUpdatedSuccess, setIsUpdatedSuccess] = useState(false);
  const [isImage, setIsImage] = useState('');
  const [imageName, setImageName] = useState('');
  const [jobData, setJobData] = useState({
    jobTitle: '',
    salary: '',
    skills: [],
    jobDescription: '',
    position: '',
    jobImage: '',
    experience: ''
  });

  useEffect(() => {
    if (ID && jobDetails && Object.keys(jobDetails).length !== 0) {
      setJobData({
        jobTitle: `${jobDetails?.JobTitle || ''}`,
        salary: `${jobDetails?.Salary || ''}`,
        skills: jobDetails?.Skills || [],
        jobDescription: `${jobDetails?.JobDescription || ''}`,
        jobImage: `${jobDetails?.JobImage || ''}`,
        position: `${jobDetails?.Position || ''}`,
        experience: `${jobDetails?.Experience || ''}`
      });
      if (jobDetails?.JobImage) {
        setIsImage(jobDetails?.JobImage);
      } else {
        setIsImage('');
      }
    }
  }, [jobDetails]);

  useEffect(() => {
    ID && dispatch(getJobById(ID));
  }, [dispatch, ID]);

  useEffect(() => {
    if (show && isUpdatedSuccess && isSucceed) {
      onHide();
      formik.resetForm();
      dispatch(updateData());
      dispatch(apiStatusClear());
      setJobData({
        jobTitle: '',
        salary: '',
        skills: '',
        jobDescription: '',
        jobImage: '',
        position: '',
        experience: ''
      });
      setIsUpdatedSuccess(false);
    }
  }, [isSucceed]);

  const formik = useFormik({
    initialValues: jobData,
    validationSchema: Yup.object({
      jobTitle: Yup.string()
        .required('Job Title is required')
        .min(3, 'Job Title must be at least 3 characters long'),
      position: Yup.string()
        .required('Position is required')
        .min(3, 'Position must be at least 3 characters long'),
      jobImage: Yup.mixed()
        .test('format', 'Only the following formats are accepted: .png, .jpg, .jpeg', (value) => {
          if (!value) {
            return true;
          }
          if (typeof value === 'string') {
            return true;
          } else return imageValidation(value);
        })
        .test('fileSize', 'The image size must be less than 1 MB', (value) => {
          if (!value) {
            return true;
          }
          if (typeof value === 'string') {
            return true;
          } else return value.size <= 1024 * 1024; // Check if file size is less than 1 MB.
        })
        .required('Job Logo is required.'),
      salary: Yup.string()
        .required('Salary is required')
        .matches(/^\d+(\.\d{1,2})?$/, 'Salary must be a valid number'),
      skills: Yup.array()
        .of(Yup.string('Should be of type string.'))
        .min(1, 'Please select at least one skill.')
        .required('Skills are required.'),
      jobDescription: Yup.string().required('Requirements are required'),
      experience: Yup.number()
        .required('Experience is required.')
        .max(100, 'Experience cannot exceed 100 years.')
        .test('valid-format', 'Invalid format. Ensure months do not exceed 11.', (value) =>
          isValidExperienceFormat(value)
        )
        .nullable()
    }),
    onSubmit: (values) => {
      const formData = new FormData();

      // Convert `values` to FormData
      Object.entries(values).forEach(([key, value]) => {
        if (key === 'experience') {
          formData.append(key, value.toString()); // Ensure `experience` is a string
        } else if (key === 'jobImage') {
          if (typeof value !== 'string') {
            formData.append(key, value); // Ensure `experience` is a string
          }
        } else if (key === 'skills') {
          for (var i = 0; i < values[key]?.length; i++) {
            formData.append('skills[]', values[key][i]);
          }
        } else {
          formData.append(key, value);
        }
      });
      if (ID) {
        dispatch(
          editJob({
            payload: formData,
            ID: ID
          })
        );
      } else {
        dispatch(addJob(formData));
      }
      setIsUpdatedSuccess(true);
    },
    enableReinitialize: true
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const handleSuggestion = (event) => {
    setSkilSuggestion(onSuggestSkils(event));
  };

  return (
    <Dialog
      header="Job"
      className={classNames('card overflow-hidden resize-none notes-dialog')}
      visible={show}
      style={{ width: '850px', height: '800px' }}
      onHide={onHide}>
      <div className="form-box-wrapper">
        <form onSubmit={formik.handleSubmit} className="p-fluid" autoComplete="off">
          <div className="form-row-wrapper">
            <div className="form-col">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label for="jobTitle" text={LABEL_TYPES.JOB_TITLE} isMandatory />
                  <span className="p-input-icon-right">
                    <InputText
                      id="jobTitle"
                      name="jobTitle"
                      placeholder={LABEL_TYPES.JOB_TITLE}
                      value={formik.values.jobTitle}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid('jobTitle')
                      })}
                    />
                  </span>
                </div>
                {getFormErrorMessage('jobTitle')}
              </div>
            </div>
            <div className="form-col">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label for="position" text={LABEL_TYPES.JOB_POSITION} isMandatory />
                  <span className="p-input-icon-right">
                    <InputText
                      id="position"
                      name="position"
                      placeholder={LABEL_TYPES.JOB_POSITION}
                      value={formik.values.position}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={classNames({
                        'p-invalid': isFormFieldValid('position')
                      })}
                    />
                  </span>
                </div>
                {getFormErrorMessage('position')}
              </div>
            </div>
            <div className="form-col">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label for="experience" text={LABEL_TYPES.JOB_EXPERIANCE} isMandatory />
                  <span className="p-input-icon-right">
                    <InputNumber
                      id="experience"
                      name="experience"
                      placeholder={LABEL_TYPES.JOB_EXPERIANCE_PLACEHOLDER}
                      value={formik.values.experience}
                      className={classNames({
                        'p-invalid': isFormFieldValid('experience')
                      })}
                      onValueChange={(e) => formik.setFieldValue('experience', e.value)}
                      mode="decimal"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      min={0}
                      max={100}
                    />
                  </span>
                </div>
                {getFormErrorMessage('experience')}
              </div>
            </div>
            <div className="form-col">
              <div className="form-group-outer">
                <div className="custom-form-group autocomplete">
                  <Label for="skills" text={LABEL_TYPES.JOB_SKILLS} isMandatory />
                  <span className="p-input-icon-right">
                    <AutoComplete
                      id="skills"
                      name="skills"
                      value={formik.values.skills}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      multiple
                      suggestions={skilSuggestion}
                      completeMethod={handleSuggestion}
                      placeholder={LABEL_TYPES.SOFT_SKILLS}
                      className={classNames({
                        'p-invalid': isFormFieldValid('skills')
                      })}
                    />
                  </span>
                </div>
                {getFormErrorMessage('skills')}
              </div>
            </div>
            <div className="form-col">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label for="salary" text={LABEL_TYPES.JOB_SALARY} isMandatory />
                  <div className="p-inputgroup w-full">
                    <InputText
                      id="salary"
                      name="salary"
                      value={formik.values.salary}
                      placeholder={LABEL_TYPES.JOB_SALARY}
                      onChange={formik.handleChange}
                      className={classNames({
                        'p-invalid': isFormFieldValid('salary'),
                        'w-full': true
                      })}
                      onBlur={formik.handleBlur}
                      keyfilter={'money'}
                    />
                    <span
                      style={{ backgroundColor: 'inherit' }}
                      className="p-inputgroup-addon add-candidate-lpa">
                      LPA
                    </span>
                  </div>
                </div>
                {getFormErrorMessage('salary')}
              </div>
            </div>
            <div className="form-col">
              <div className="form-group-outer upload-document-wrapper">
                <div className="custom-form-group">
                  <Label htmlFor="file" text={BUTTON_TYPES.JOB_LOGO} isBold />
                  <input
                    hidden
                    ref={imageRef}
                    type="file"
                    id="jobImage"
                    name="jobImage"
                    onChange={(e) => {
                      formik.setFieldValue('jobImage', e.target.files[0]);
                      setImageName(e.target.files[0].name);
                      setIsImage('');
                    }}
                    accept="image/png, image/jpeg ,image/jpg"
                    className={classNames({
                      'p-invalid': isFormFieldValid('jobImage')
                    })}
                  />
                </div>
                <div className="flex flex-wrap align-items-center">
                  <CustomButton
                    variant="contained"
                    className="gray-btn"
                    onClick={() => {
                      imageRef.current.click();
                    }}>
                    <div>
                      <i className="pi pi-fw pi-upload mr-1" />
                    </div>
                  </CustomButton>
                  {ID ? (
                    isImage ? (
                      <ResumeButton
                        onClick={() => openResumeLink(`${imagePathBase}/${ID}/${isImage}`)}
                      />
                    ) : (
                      <p>{imageName}</p>
                    )
                  ) : (
                    <p>{imageName}</p>
                  )}
                </div>
              </div>
              {getFormErrorMessage('jobImage')}
            </div>
            <div className="form-col full-width">
              <div className="form-group-outer">
                <div className="custom-form-group">
                  <Label for="jobDescription" text={LABEL_TYPES.JOB_DESCRIPTION} isMandatory />
                  <Editor
                    id="jobDescription"
                    name="jobDescription"
                    onInit={(_evt, editor) => (editorRef.current = editor)}
                    value={formik.values.jobDescription}
                    init={{
                      height: 500,
                      menubar: true,
                      branding: false,
                      plugins: TINY_MCE_PLUGINS,
                      ...(isDarkMode() && { content_style: `body { color: #FFF }` })
                    }}
                    onEditorChange={(editor) => formik.setFieldValue('jobDescription', editor)}
                  />
                </div>
                {getFormErrorMessage('jobDescription')}
              </div>
            </div>
          </div>
          <div className="form-btn-wrapper justify-flex-end">
            <CustomButton
              variant="contained"
              onClick={() => {
                onHide();
                setJobData({
                  jobTitle: '',
                  salary: '',
                  skills: '',
                  jobDescription: '',
                  jobImage: '',
                  position: '',
                  experience: ''
                });
                formik.resetForm();
              }}
              color="error"
              className="gray-btn">
              {BUTTON_TYPES.CANCEL}
            </CustomButton>
            <CustomButton type="submit" variant="contained">
              {BUTTON_TYPES.SAVE}
            </CustomButton>
          </div>
        </form>
      </div>
    </Dialog>
  );
}
